import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'first_name' }
      { data: 'last_name' }
      { data: 'email' }
      { data: 'role' }
      { data: 'created_at', className: 'dt-right text-nowrap' }
      { data: 'dt_actions', className: 'dt-center text-nowrap', searchable: false, orderable: false }
    ]
    # Order by 'last name'
    order: [[1, 'asc']]
