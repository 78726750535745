import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'active', className: 'dt-center' }
      { data: 'project' }
      { data: 'stage' }
      { data: 'drop_reason' }
      { data: 'created_at', className: 'dt-right text-nowrap' }
      { data: 'dt_actions', className: 'dt-center text-nowrap', searchable: false, orderable: false }
    ]
    # Order by 'active', then 'created_at'
    order: [[0, 'desc'], [3, 'desc']]
