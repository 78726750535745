import { Controller } from "@hotwired/stimulus"

export default class extends Controller
  connect: ->
    $(this.element).on 'show.bs.modal', (event) ->
      button = $(event.relatedTarget)
      resumeId = button.data('resume-id')

      $(this).find("#shareResumeBody").html('<div class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>')

      loadForm = $(this).find('.load-form')
      loadForm.attr('action', loadForm.data('action-pattern').replace('RESUME_ID', resumeId))
      loadForm[0].requestSubmit() # `submit` method doesn't work with turbo
