import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'job_profile' }
      { data: 'average_rating', searchable: false, orderable: false }
      { data: 'nestor_rating', searchable: false, orderable: false }
      { data: 'reviewer_rating', searchable: false, orderable: false }
    ]
    lengthChange: false
    # Order by 'job_profile'
    order: [[0, 'asc']]
    searching: false

  connect: ->
    super.connect()
    # Autofocus on rating input
    $(this.element).on 'shown.bs.collapse', '.rating-editor', () ->
      $(this).find('input[name="rating[value]"]').select()
