// Based on https://github.com/mkhairi/jquery-datatables/blob/master/lib/generators/jquery/datatables/templates/javascripts/initializer.js.tt

import 'datatables.net-bs5'

//Global setting and initializer

$.extend( $.fn.dataTable.defaults, {
  ajax: {
    data: function(d) {
      // Since we use ajax with `POST` and `turbo` doesn't intercept ajax requests (opposite to deprecated rails-ujs),
      // we have to extract CSRF token and pass it like form parameter. Based on:
      // https://github.com/rails/rails/blob/11341fdb3a1664ba58edf729ed46e04cd0e20ed6/actionview/app/assets/javascripts/rails-ujs/utils/csrf.coffee
      const csrfName = document.querySelector('meta[name=csrf-param]')
      const csrfToken = document.querySelector('meta[name=csrf-token]')
      d[csrfName.content] = csrfToken.content;
    },
    type: 'POST'
  },
  language: {
    // Append help button to search input.
    search: '_INPUT_ <a class="iconic-button text-info" data-bs-toggle="modal" href="#searchHelp" title="Search Help"><i class="far fa-question-circle fa-lg"></i></a>',
    searchPlaceholder: 'Type search text here'
  },
  pagingType: 'full_numbers',
  processing: true,
  responsive: true,
  serverSide: true,
});

$.fn.dataTable.ext.errMode = function(settings, techNote, message) {
  const msg = `Datatable error (technical note ${techNote}).`;
  const notification = $('#notification-area #datatable-error-message');
  notification.html(msg);
  notification.show();
};

// Set up AJAX URL based on table's `data-source` attribute.
$(document).on('preInit.dt', function(e, settings) {
  var api, table_id, url;
  api = new $.fn.dataTable.Api(settings);
  table_id = "#" + api.table().node().id;
  url = $(table_id).data('source');
  if (url) {
    return api.ajax.url(url);
  }
});
