/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Activate Active Storage
// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import 'jquery'
import '@hotwired/turbo-rails'
import 'bootstrap'
import '@nathanvda/cocoon'
import 'select2'

require('../channels')
require('../controllers')
require('../datatables')

import '../application.scss';

$(document).on('turbo:submit-start', function() {
  $(document).find('.disabled-during-submit').prop('disabled', true);
});

$(document).on('turbo:submit-end', function() {
  $(document).find('.disabled-during-submit').prop('disabled', false);
});
