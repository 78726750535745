import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'nestor_rating' }
      { data: 'expert_avg_rating' }
      { data: 'candidate' }
      { data: 'tags' }
      { data: 'resume' }
    ]
    # Order by 'nestor_rating'
    order: [[0, 'desc']]
