import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'skill' }
      { data: 'expert' }
      { data: 'score' }
    ]
    lengthChange: false
    # Order by 'skill', then 'expert'
    order: [[0, 'asc'], [1, 'asc']]
    searching: false
