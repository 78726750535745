import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'first_name' }
      { data: 'last_name' }
      { data: 'contacts', orderable: false }
      { data: 'tags' }
      { data: 'max_nestor_rating', searchable: false }
      { data: 'created_at', className: 'dt-right text-nowrap' }
      { data: 'dt_actions', className: 'dt-center text-nowrap', searchable: false, orderable: false }
    ]
    # Order by 'created_at'
    order: [[5, 'desc']]
