import { Controller } from "@hotwired/stimulus"

export default class extends Controller
  connect: ->
    $(this.element).on 'show.bs.modal', () ->
      # Make sure `src` points to new form. Can be altered by previous activity.
      turboFrame = $(this).find('#new-candidate-project-state-form')
      turboFrame.attr('src', turboFrame.attr('data-src'))

    $(this.element).on 'hide.bs.modal', () ->
      $("div[data-controller='datatables--candidate-project-states'] table.datatable").DataTable().ajax.reload()
