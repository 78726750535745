import { Controller } from "@hotwired/stimulus"

export default class extends Controller
  connect: ->
    $(this.element).on 'show.bs.modal', (event) ->
      button = $(event.relatedTarget)
      candidateId = button.data('candidate-id')
      stateId = button.data('id')

      # Clean up possible previous candidate details
      for section in ['stages', 'notes', 'scores', 'my-scores']
        $(document).find("#candidate-project-details-#{section}").html('<div class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>')
      $(document).find("#candidateProjectStateDetailsTitle").html("Candidate Details for Project")

      loadForm = $(this).find('.load-form')
      loadForm.attr('action', loadForm.data('action-pattern').replace('CANDIDATE_ID', candidateId).replace('STATE_ID', stateId))
      loadForm[0].requestSubmit() # `submit` method doesn't work with turbo

    $(this.element).on 'hide.bs.modal', () ->
      $("div[data-controller='datatables--candidate-project-states'] table.datatable").DataTable().ajax.reload()
