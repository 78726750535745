import { Controller } from "@hotwired/stimulus"

export default class extends Controller
  nextQueryId: 1
  @targets: ['query', 'queryTemplate']

  queryTargetConnected: (element) ->
    root = $(element)
    if root.parents("[data-candidates-search-target='queryTemplate']").length > 0
      # Don't apply select2 to template.
      return

    query = root.find('input[type="text"]')
    query.attr('name', "queries[#{@nextQueryId}]")
    query.attr('disabled', false)

    select = root.find('select')
    select.attr('name', "fields[#{@nextQueryId}][]")
    select.attr('disabled', false)
    # Add a class to disable the element during form submission. Template should stay disabled all time so add class here.
    select.addClass('disabled-during-submit')
    select.select2({
      placeholder: 'Select fields to search in',
      width: '100%'
    })

    ++@nextQueryId

  addQuery: (event) ->
    event.preventDefault()
    template = $(this.queryTemplateTarget)
    queryFields = template.clone()
    queryFields.removeAttr('data-candidates-search-target')
    template.parent().append(queryFields)
    queryFields.removeClass('d-none')

  removeQuery: (event) ->
    event.preventDefault()
    queryFields = $(event.target).parents('.query-fields')
    queryFields.remove()
