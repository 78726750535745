import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'active', className: 'dt-center' }
      { data: 'name' }
      { data: 'headcount', className: 'dt-right' }
      { data: 'created_at', className: 'dt-right text-nowrap' }
      { data: 'dt_actions', className: 'dt-center text-nowrap', searchable: false, orderable: false }
    ]
    # Order by 'created_at'
    order: [[3, 'desc']]
