import { Controller } from "@hotwired/stimulus"
# Import Sortable with all plugins
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

export default class extends Controller
  @values: { moveable: { type: Boolean, default: true } }

  connect: ->
    Sortable.create(this.element, {
      group: 'project-stage',
      draggable: '.card',
      chosenClass: 'border-info',
      disabled: !this.moveableValue,
      dragClass: 'border-warning',
      ghostClass: 'bg-secondary',
      # MultiDrag plugin
      multiDrag: true,
      multiDragKey: 'ctrl',
      selectedClass: 'border-primary',

      onEnd: (event) ->
        # Sortable sets `item` while MultiDrag plugin sets `items`.
        items = if event.items.length then event.items else [event.item]
        oldStageId = event.from.getAttribute('data-id')
        newStageId = event.to.getAttribute('data-id')

        for i of items
          item = items[i]
          Sortable.utils.deselect(item)

          if oldStageId != newStageId
            moveForm = $(item).find('.move-form')
            moveForm.find('input[name="candidate_project_state[new_stage_id]"]').attr('value', newStageId)
            moveForm[0].requestSubmit() # `submit` method doesn't work with turbo
    })
