import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'first_name' }
      { data: 'last_name' }
      { data: 'role' }
    ]
    # Order by 'last_name', 'first_name'
    order: [[1, 'asc'], [0, 'asc']]
