import Controller from "./base"

export default class extends Controller
  DATATABLE_OPTIONS:
    columns: [
      { data: 'active', className: 'dt-center' }
      { data: 'title' }
      { data: 'system' }
      { data: 'periodicity', className: 'dt-right' }
      { data: 'tags' }
      { data: 'created_at', className: 'dt-right text-nowrap' }
      { data: 'last_run_at', className: 'dt-right text-nowrap' }
      { data: 'dt_actions', className: 'dt-center text-nowrap', searchable: false, orderable: false }
    ]
    # Order by 'title'
    order: [[1, 'asc']]
